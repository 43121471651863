export class CampaignModel {
  ageMax: number;
  ageMin: number;
  brandCompanyName: string;
  callAction: string;
  campaignLink: string;
  campaignLogo?: string;
  campaignSpent: number;
  campaignWallet: number;
  campaignWalletType: string | null;
  campaignWalletTypeId?: null | number;
  campaignBudget?: null | number;
  contentLove: string;
  createdDatetime: Date;
  dontDos: string[];
  moodBoardUrls: string[] | null;
  gender: string;
  language: string;
  heroImageUrl: string;
  heroImage?: string;
  id: number;
  infoDemographic: string;
  interests: string[];
  linkedinInterests: string[];
  isLive: boolean;
  moodImagesCount: number;
  moodImages: { image: string }[];
  openUntilDate: Date | string;
  socialPlatforms: SocialPlatforms;
  platformName: string;
  postSubmissions: number;
  postsAccepted: number;
  preTags: any;
  proCategorySecondary: any;
  campaignName: string;
  campaignDescription: string;
  pubGuides: string[];
  publishBriefApp: Date | string;
  responseTime: string;
  responseTimeDate: any;
  reviewed: boolean;
  subUser?: number | null;
  summarySent: boolean;
  updatedDate: Date;
  userEmail: string;
  objective?: number;
  showPrices: boolean;
  totalPostsCosts?: number;
  privateInfluencers?: string[];
  brandEmail: string;
  reward: Reward;
  vouchers: {
    active: number;
    total: number;
  };
  isShared: boolean;
}

export interface Reward {
  cash: boolean;
  voucher_percentage?: number;
  voucher_fixed?: number;
}

export interface SocialPlatforms {
  platformFb: boolean;
  platformInsta: boolean;
  platformInstaStory: boolean;
  platformTiktok: boolean;
  platformReels: boolean;
  platformLinkedin: boolean;
}
