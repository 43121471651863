import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CampaignModel, PostModel, PostStatusTypes, UserModel } from '@app/shared/model';
import { Observable, Subject, throwError } from 'rxjs';
import { DialogRef, DialogService } from '@ngneat/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { TranslateService } from '@ngx-translate/core';
import * as campaignOverviewActions from '@app/store';
import { Actions, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { PostService } from '@app/services/post.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureFlagsService } from '@app/services/feature-flag.service';
import { CONFIG } from '@config/configuration';
import {
  approveCampaignPost,
  changeCampaignPost,
  declineCampaignPost, getCampaigns,
  selectBrandFunds,
  selectCampaignOnly,
  selectCampaignTags,
  selectUserInfo
} from '@app/store';
import { catchError, takeUntil } from 'rxjs/operators';
import { PostReviewChangeComponent } from '@app/dialogs/post-review-change/post-review-change.component';
import { PostDeclineChangeComponent } from '@app/dialogs/post-decline-change/post-decline-change.component';
import { CampaignService } from '@app/services/campaign.service';

@Component({
  selector: 'app-post-tab',
  templateUrl: './post-tab.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class PostTabComponent implements OnInit, OnDestroy {
  post: PostModel;
  IVA: number;
  campaignTags: any;
  profileImage: string;
  destroyed$ = new Subject<boolean>();
  statusChangingProcess = false;
  requestInsightProcess = false;
  brandFunds: number;
  showPrices: boolean;
  disableButtons: boolean;
  postId: number;
  campaignId: number;
  isAdditionalUser = false;
  featureFlagWalletEnabled = false;
  isEditing = false;
  editingHistory: any[] = [];
  feedbacksHistory: any[] = [];
  isLoading = true;
  private campaign: CampaignModel;

  constructor(
    //  public ref: DialogRef,
    private store: Store<AppState>,
    private dialog: DialogService,
    private translate: TranslateService,
    private actions: Actions,
    private toastr: ToastrService,
    private postService: PostService,
    private campaignService: CampaignService,
    private router: Router,
    private route: ActivatedRoute,
    private featureFlagService: FeatureFlagsService
  ) {}

  ngOnInit(): void {
    this.featureFlagWalletEnabled = this.featureFlagService.isFeatureEnabled('campaignWallet');

    this.postId = +this.route.snapshot.paramMap.get('id');
    const postPlatformName = this.route.snapshot.queryParamMap.get('platform_name');
    this.campaignId = +this.route.parent.snapshot.paramMap.get('id');
    this.store.pipe(select(selectUserInfo)).subscribe(() => {
      this.campaignService.getCampaignFromApi(this.campaignId).subscribe((campaign) => {
        this.campaign = campaign;
        this.showPrices = campaign.showPrices;
        this.campaignTags = this.campaign.preTags;

        return this.postService
          .getPost(this.postId, postPlatformName)
          .pipe(
            catchError((err) => {
              if (err.status === 404) {
                this.router.navigate(['404']);
              }
              return throwError(err);
            })
          )
          .subscribe((campaignPost) => {
            this.post = campaignPost;
            this.IVA = +(this.post.postPriceTechFee * CONFIG.platform?.iva).toFixed(2);
            this.profileImage = this.post.socialAccountInfo.profilePicture;
            this.getHistory();
            this.isLoading = false;
          });
      });
    });

    const { platformName } = CONFIG;
    this.postStatusUpdateSuccess();
    this.postStatusUpdateFail();
    this.checkWalletFunds();

    this.store.pipe(select(selectUserInfo), takeUntil(this.destroyed$)).subscribe((user: UserModel) => {
      if (user) {
        this.isAdditionalUser = !!user.isAdditionalBrandUser;
        if (platformName === 'io_platform' && this.isAdditionalUser) {
          this.disableButtons = true;
        }
      }
    });
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  checkWalletFunds(): void {
    this.store.pipe(select(selectBrandFunds), takeUntil(this.destroyed$)).subscribe((funds) => {
      this.brandFunds = funds;
    });
  }

  private getHistory() {
    this.postService.getPostHistory(this.postId).subscribe(({ campaignPost }) => {
      const { histories } = campaignPost;
      this.editingHistory = histories.post_history;
      this.feedbacksHistory = histories.feedbacks_history;

      if (!this.editingHistory) {
        this.editingHistory = [
          {
            created_datetime: this.post.createdDatetime,
            image_url_timestamp: this.post.postImageInfo.imageUrl,
            post_copy: this.post.postText,
            post_social_type: this.post.postSocialType,
            price: {
              post_price: this.post.postPrice,
              post_price_with_fees: this.post.postPriceTechFee,
              post_price_final: this.post.postPriceFinal,
            },
            video_timestamp: this.post.videoUrl ? this.post.videoUrl : '',
          },
        ];
      }

      if (this.editingHistory) {
        this.editingHistory.forEach((history) => {
          if (
            history.image_url_timestamp &&
            !history.image_url_timestamp.includes('http:') &&
            !history.image_url_timestamp.includes('https:')
          ) {
            history.image_url_timestamp = 'https://' + history.image_url_timestamp;
          }

          if (
            history.video_timestamp &&
            !history.video_timestamp.includes('http:') &&
            !history.video_timestamp.includes('https:')
          ) {
            history.video_timestamp = 'https://' + history.video_timestamp;
          }
        });
      }
    });
  }

  openImage(url: string): void {
    window.open(url, '_blank');
  }
  public copy(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public openPostInNewTab(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }

  postStatusUpdateSuccess(): void {
    this.actions
      .pipe(ofType(campaignOverviewActions.postStatusUpdatedSuccess), takeUntil(this.destroyed$))
      .subscribe((action) => {
        const { status } = action;
        let successMessage;
        const approvedPostMessage = this.translate.instant('campaignOverview.post.dialog.postApprovedSuccessMsg');
        const declinedPostMessage = this.translate.instant('campaignOverview.post.dialog.postDeclinedSuccessMsg');
        const changePostMessage = this.translate.instant('campaignOverview.post.dialog.sendFeedbackSuccessMsg');

        if (status === PostStatusTypes.APPROVED) {
          successMessage = approvedPostMessage;
        }

        if (status === PostStatusTypes.DECLINED) {
          successMessage = declinedPostMessage;
        }

        if (status === PostStatusTypes.PENDING) {
          successMessage = changePostMessage;
        }

        this.statusChangingProcess = false;
        this.toastr.success(successMessage);
      });
  }
  postStatusUpdateFail(): void {
    this.actions
      .pipe(ofType(campaignOverviewActions.postStatusUpdateFail), takeUntil(this.destroyed$))
      .subscribe(() => {
        const postStatusUpdateFail = this.translate.instant('campaignOverview.post.dialog.postStatusUpdateFailMsg');

        this.statusChangingProcess = false;
        this.toastr.error(postStatusUpdateFail);
      });
  }
  setDefaultProfileImage(): void {
    this.profileImage = 'assets/img/icons/user-default.png';
  }
  downloadMedia(postId: number, event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.postService
      .downloadMedia(postId)
      .pipe(
        // @ts-ignore
        catchError((err) => {
          const errorResponse = this.translate.instant('commonMsg.somethingWentWrong');
          this.toastr.warning(errorResponse);
        })
      )
      .subscribe((response) => {
        const successResponse = this.translate.instant('campaignOverview.post.dialog.downloadMediaLinkSuccessMsg');
        this.toastr.success(successResponse);
      });
  }
  approvePost(): void {
    const body = this.translate.instant('campaignOverview.post.dialog.approve');
    const fundsError = this.translate.instant('campaignOverview.post.dialog.insufficientFailMsg');
    const exceedCampaignWalletError = this.translate.instant('campaignOverview.post.dialog.walletExceededMsg');

    if (this.post.postPriceFinal > this.brandFunds) {
      this.dialog.error({ title: '', body: fundsError });
      return;
    }

    // featureFlag: campaign wallet
    if (
      this.featureFlagWalletEnabled &&
      this.campaign.campaignSpent + this.post.postPriceFinal > this.campaign.campaignWallet
    ) {
      this.dialog.error({ title: '', body: exceedCampaignWalletError });
      return;
    }

    this.dialog.confirm({ title: '', body }).afterClosed$.subscribe((confirmed) => {
      if (confirmed) {
        this.statusChangingProcess = true;
        this.store.dispatch(approveCampaignPost({ post: this.post, status: PostStatusTypes.APPROVED }));
      }
    });
  }
  changePost(): void {
    const changePost = this.dialog.open(PostReviewChangeComponent, {
      data: {
        feedback: this.post.brandFeedback,
      },
      closeButton: true,
    });

    changePost.afterClosed$.subscribe((feedback) => {
      if (feedback && feedback.trim() && feedback !== this.post.brandFeedback) {
        this.store.dispatch(changeCampaignPost({ post: this.post, status: PostStatusTypes.PENDING, feedback }));
      }
    });
  }
  declinePost(): void {
    const declinePost = this.dialog.open(PostDeclineChangeComponent, {
      closeButton: true,
    });

    declinePost.afterClosed$.subscribe((declineReason) => {
      if (declineReason && declineReason.trim()) {
        this.store.dispatch(
          declineCampaignPost({ post: this.post, status: PostStatusTypes.DECLINED, feedback: declineReason })
        );
      }
    });
  }

  openPostInsight(event, post): void {
    event.preventDefault();
    event.stopPropagation();
    this.requestInsightProcess = true;
    this.postService
      .getPostInsights(this.post.id)
      .pipe(
        // @ts-ignore
        catchError((err) => {
          this.toastr.warning('Can not get insights for this post');
          this.requestInsightProcess = false;
        })
      )
      .subscribe((insights) => {
        this.requestInsightProcess = false;
        this.postService.setInsights(insights);
        this.router.navigate(['/insights', post.id]);
      });
  }
}
