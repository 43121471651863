<app-campaign-wizard-step (nextStepClicked)="goToNextStep()" [stepTitle]='stepTitle' [enabledNextStep]='enableNextStep()' (previousStepClicked)='goToPreviousStep()'>
  <form [formGroup]="campaignForm" class="new-campaign-form">
    <div class="builder__cont step-2">
      <div class="builder__head">
        <h2 class="h1">{{ 'newCampaign.campaignStep.title' | translate }}</h2>
      </div>
      <div class="builder__main">
        <div class="builder__col">
          <div class="form__box">
            <span class="form__label">{{'newCampaign.review.brandCompName' | translate}}</span>
            <input formControlName="brandCompanyName" type="text" class="form__input">
          </div>
          <div class="builder__grid-two">
            <div class="form__box">
                                        <span class="form__label">
                                            {{ 'newCampaign.campaignStep.uploadImage' | translate }}
<!--                                            <span class="info tooltip-wrap">-->
<!--                                                <img src="assets/img/sc_icons/message-question.svg" alt="info">-->
<!--                                                <span class="tooltip right">Voucher: Fixed discount amount</span>-->
<!--                                            </span>-->
                                        </span>
              <div class="upload-img large">
                <label class="upload-img__group" *ngIf='!showHeroCropper && !heroImageUrl' style="z-index: 5">
                  <input type="file" (change)="fileHeroChangeEvent($event)">
                  <img src="assets/img/sc_icons/add-circle.svg" alt="icon" class="upload-img__icon">
                  <span class="upload-img__text">{{ 'newCampaign.campaignStep.aspectRatio' | translate }}</span>
                </label>


                <div class="upload-img__box" style="z-index: 3">
                              <img *ngIf='heroImageUrl' [src]="heroImageUrl" alt="" class="upload-img__img">

                                <image-cropper
                                  [imageChangedEvent]="imageHeroChangedEvent"
                                  [maintainAspectRatio]="true"
                                  [aspectRatio]="4 / 3"
                                  [style.display]="showHeroCropper ? null : 'none'"
                                  format="png"
                                  (imageCropped)="imageHeroCropped($event)"
                                  (imageLoaded)="imageHeroLoaded()"
                                ></image-cropper>



                  <div class="upload-img__close" (click)='deleteHeroImage()' *ngIf='croppedHeroImage || heroImageUrl' style="z-index: 5">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 33C26.25 33 33 26.25 33 18C33 9.75 26.25 3 18 3C9.75 3 3 9.75 3 18C3 26.25 9.75 33 18 33Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M13.7539 22.245L22.2439 13.755" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M22.2439 22.245L13.7539 13.755" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="form__box"></div>
            </div>
          </div>
          <div class="builder__col">
            <div class="form__box">
              <span class="form__label">{{'newCampaign.review.campaignName' | translate}}</span>
              <input formControlName="campaignName" type="text" class="form__input">
            </div>
            <div class="form__box">
                                    <span class="form__label">
                                        {{'newCampaign.review.productDescription' | translate}}
<!--                                      <span class="info tooltip-wrap">-->
<!--                                            <img src="assets/img/sc_icons/message-question.svg" alt="info">-->
<!--                                            <span class="tooltip right">Voucher: Fixed discount amount</span>-->
<!--                                        </span>-->
                                    </span>
              <textarea formControlName="campaignDescription" class="form__textarea"></textarea>
            </div>
            <div class="form__box">
                                    <span class="form__label">
                                        {{'newCampaign.review.link' | translate}}
<!--                                      <span class="info tooltip-wrap">-->
<!--                                            <img src="assets/img/sc_icons/message-question.svg" alt="info">-->
<!--                                            <span class="tooltip right">Voucher: Fixed discount amount</span>-->
<!--                                        </span>-->
                                    </span>
              <input formControlName="campaignLink" type="text" class="form__input">
              <div *ngIf="campaignLinkInput.invalid && (campaignLinkInput.dirty || campaignLinkInput.touched)">
                <span style="color: var(--btn-danger)" *ngIf="campaignLinkInput.errors.required">
                  {{'newCampaign.review.linkRequired' | translate}}
                </span>
                <span style="color: var(--btn-danger)" *ngIf="campaignLinkInput.errors.invalidLink">
                  {{'newCampaign.review.linkWrongFormat' | translate}}
                </span>
              </div>
            </div>
            <div class="form__box">
              <span class="form__label">{{ 'newCampaign.campaignStep.isShared' | translate }}</span>
              <label class="toggle">
                <input type="checkbox" formControlName="isShared">
                <span class="toggle__box"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
  </form>
</app-campaign-wizard-step>
