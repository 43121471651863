import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromCampaignOverviewActions from './campaign-overview.actions';
import { CampaignService } from '@app/services/campaign.service';
import { PostService } from '@app/services/post.service';
import { PostStatusTypes } from '@app/shared/model';

@Injectable()
export class CampaignOverviewEffects {
  constructor(private actions$: Actions, private campaignService: CampaignService, private postService: PostService) {}

  getSingleCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaignOverviewActions.getSingleCampaign),
      mergeMap(({ id }) =>
        this.campaignService
          .getCampaignFromApi(id)
          .pipe(map((campaign) => fromCampaignOverviewActions.getSingleCampaignSuccess({ campaign })))
      )
    )
  );

  getSingleCampaignAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaignOverviewActions.getSingleCampaignSuccess),
      switchMap(({ campaign }) =>
        this.campaignService
          .getCampaignAnalytics(campaign.id)
          .pipe(map((analytics) => fromCampaignOverviewActions.getSingleCampaignAnalyticsSuccess({ analytics })))
      )
    )
  );

  getSingleCampaignPosts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaignOverviewActions.getSingleCampaignSuccess),
      switchMap(({ campaign }) =>
        this.postService
          .getPosts(campaign.id)
          .pipe(map((posts) => fromCampaignOverviewActions.getSingleCampaignPostsSuccess({ posts, isLoaded: true })))
      )
    )
  );

  makePostSeen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaignOverviewActions.makePostSeen),
      switchMap(({ postId }) =>
        this.postService
          .makeSeenPost(postId)
          .pipe(map((posts) => fromCampaignOverviewActions.makePostSeenSuccess({ postId })))
      )
    )
  );

  approveCampaignPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaignOverviewActions.approveCampaignPost),
      switchMap(({ post, status }) =>
        this.postService.updatePostStatus(post, status).pipe(
          map(() => fromCampaignOverviewActions.postStatusUpdatedSuccess({ postId: post.id, status: PostStatusTypes.APPROVED })),
          catchError(() => fromCampaignOverviewActions.postStatusUpdateFail)
        )
      )
    )
  );

  changeCampaignPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaignOverviewActions.changeCampaignPost),
      switchMap(({ post, status, feedback }) =>
        this.postService.updatePostStatus(post, status, feedback).pipe(
          map(() =>
            fromCampaignOverviewActions.postStatusUpdatedSuccess({ postId: post.id, status: PostStatusTypes.PENDING, feedback })
          ),
          catchError(() => fromCampaignOverviewActions.postStatusUpdateFail)
        )
      )
    )
  );

  declineCampaignPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaignOverviewActions.declineCampaignPost),
      switchMap(({ post, status, feedback }) =>
        this.postService.updatePostStatus(post, status, feedback).pipe(
          map(() => fromCampaignOverviewActions.postStatusUpdatedSuccess({ postId: post.id, status: PostStatusTypes.DECLINED })),
          catchError(() => fromCampaignOverviewActions.postStatusUpdateFail)
        )
      )
    )
  );

  contactBrand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaignOverviewActions.contactBrand),
      switchMap(({ brandClients }) =>
        this.postService.contactBrand(brandClients).pipe(
          map(() => fromCampaignOverviewActions.contactBrandSuccess()),
          catchError(({ error }) => of(fromCampaignOverviewActions.contactBrandFail({ error })))
        )
      )
    )
  );
}
